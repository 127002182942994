<template>
  <div class="setting-manage-page">
    <div class="ym-title">基本信息</div>
    <el-row class="row-line">
      <el-col :span="6" class="col-line">
        <i class="iconfont iconzhanghaotouxiang iconstyle"></i>
        <span class="account-title">账号头像</span>
      </el-col>
      <el-col :span="6" class="col-line">
        <img v-if="accountInfo.sex=='1'" class="avatar" src="../../assets/img/boy.png" alt=""></img>
        <img v-else-if="accountInfo.sex=='2'" class="avatar" src="../../assets/img/girl.png" alt=""></img>
        <img v-else class="avatar" src="../../assets/img/boy.png" alt=""></img>
      </el-col>
    </el-row>

    <el-row class="row-line">
      <el-col :span="6" class="col-line">
        <i class="iconfont iconqiyezhanghaodenglu iconstyle"></i>
        <span class="account-title">企业登录账号</span>
      </el-col>
      <el-col :span="6" class="col-line">
        <span class="account-content">{{accountInfo.loginName}}</span>
      </el-col>
    </el-row>

    <el-row class="row-line">
      <el-col :span="6" class="col-line">
        <i class="iconfont icondenglumima iconstyle"></i>
        <span class="account-title">登录密码</span>
      </el-col>
      <el-col :span="6" class="col-line">
        <span class="account-passw">············</span>
      </el-col>
      <el-col :span="4" class="col-line">
        <el-button class="button" type="text" @click="dialogFormPwd = true">修改密码</el-button>
      </el-col>
    </el-row>

    <el-row class="row-line">
      <el-col :span="6" class="col-line">
        <i class="iconfont iconqiyemingchengBduan iconstyle"></i>
        <span class="account-title">企业名称</span>
      </el-col>
      <el-col :span="6" class="col-line">
        <span class="account-content">{{accountInfo.companyName}}</span>
      </el-col>
       <el-col :span="4" class="col-line">
        <el-button class="btns button" type="text" @click="goTo">企业资料完善/变更</el-button>
      </el-col>
    </el-row>

    <el-row class="row-line">
      <el-col :span="6" class="col-line">
        <i class="iconfont iconqiyefarenxingming iconstyle"></i>
        <span class="account-title">法定代表人姓名</span>
      </el-col>
      <el-col :span="6" class="col-line">
        <span class="account-content">{{accountInfo.legalPersonName}}</span>
      </el-col>
    </el-row>

    <el-row class="row-line">
      <el-col :span="6" class="col-line">
        <i class="iconfont iconshenfenzhenghao iconstyle"></i>
        <span class="account-title">法定代表人身份证号</span>
      </el-col>
      <el-col :span="6" class="col-line">
        <span class="account-content">{{accountInfo.legalPersonIdcard}}</span>
      </el-col>
    </el-row>

    <el-row class="row-line">
      <el-col :span="6" class="col-line">
        <i class="iconfont iconshoujihaoma iconstyle"></i>
        <span class="account-title">手机号码</span>
      </el-col>
      <el-col :span="6" class="col-line">
        <span class="account-content">{{accountInfo.mobilephone}}</span>
      </el-col>
       <el-col :span="4" class="col-line">
        <el-button class="button" type="text" @click="dialogFormPhone = true">修改手机号码</el-button>
      </el-col>
    </el-row>

    <el-row class="row-line">
      <el-col :span="6" class="col-line">
        <i class="iconfont iconyouxiang iconstyle"></i>
        <span class="account-title">公司(企业)邮箱</span>
      </el-col>
      <el-col :span="6" class="col-line">
        <span class="account-content">{{accountInfo.companyEmail}}</span>
      </el-col>
    </el-row>

  <!-- 修改登录密码 -->
  <el-dialog title="修改登录密码" :visible.sync="dialogFormPwd" width="500px">
  <el-form class="ym-dialog-form" :model="formPwd" :rules="pwdRules" ref="formPwd" label-width="120px">
    <el-form-item label="原密码" prop="loginPwd">
      <el-input  v-model="formPwd.loginPwd" type="password" show-password placeholder="请输入原始登录密码" minlength="8" maxlength="20" clearable></el-input>
    </el-form-item>
    <el-form-item label="新密码" prop="newLoginPwd">
      <el-input  v-model="formPwd.newLoginPwd" type="password" show-password placeholder="请输入新登录密码" minlength="8" maxlength="20" clearable></el-input>
    </el-form-item>
    <el-form-item label="确认登录密码" prop="confirmNewLoginPwd">
      <el-input  v-model="formPwd.confirmNewLoginPwd" type="password" show-password placeholder="请确认新登录密码" minlength="8" maxlength="20" clearable></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormPwd = false" plain round size="medium">取 消</el-button>
    <el-button type="primary" @click="fromPwdValidateAndSubmit" round size="medium">确 定</el-button>
  </div>
</el-dialog>

<!-- 修改手机号码 -->
  <el-dialog title="修改手机号码" :visible.sync="dialogFormPhone" width="500px">
  <el-form class="ym-dialog-form" :model="formPhone" :rules="phoneRules" ref="formPhone" label-width="120px">

    <el-form-item label="手机号码" prop="mobilePhone">
      <el-input  v-model="formPhone.mobilePhone" type="text" placeholder="请输入手机号码" maxlength="11" clearable></el-input>
    </el-form-item>

  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormPhone = false" plain round size="medium">取 消</el-button>
    <el-button type="primary" @click="fromPhoneValidateAndSubmit" round size="medium">确 定</el-button>
  </div>
</el-dialog>
   
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/auth.js'
import md5 from 'js-md5'
export default {
  components: {},
  data() {
    return {
      loading: false,
      userId: JSON.parse(getUserInfo()).userId,
      accountInfo: {},
      dialogFormPwd: false,
      formPwd: {
        loginPwd: '',
        newLoginPwd: '',
        confirmNewLoginPwd: ''
      },
      pwdRules: {
        loginPwd: [
          { type: 'string', required: true, message: '请输入原始登录密码' },
          { type: 'string', min: 8, max: 20, message: '密码长度为8-20位' },
          {
            type: 'string',
            pattern: /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,20}$/,
            message: '至少包含数字/字母/字符2种组合'
          }
        ],
        newLoginPwd: [
          { type: 'string', required: true, message: '请输入新登录密码' },
          { type: 'string', min: 8, max: 20, message: '密码长度为8-20位' },
          {
            type: 'string',
            pattern: /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,20}$/,
            message: '至少包含数字/字母/字符2种组合'
          }
        ],
        confirmNewLoginPwd: [
          { type: 'string', required: true, message: '请确认新登录密码' },
          {
            type: 'string',
            validator: (r, v, c) => (this.formPwd.newLoginPwd === v ? c() : c(false)),
            message: '两次输入密码不一致'
          }
        ]
      },
      dialogFormPhone: false,
      formPhone: {
        mobilePhone: ''
      },
      phoneRules: {
        mobilePhone: [
          { type: 'string', required: true, message: '请输入手机号码' },
          {
            type: 'string',
            pattern: /^1[3456789]\d{9}$/,
            message: '手机号码不正确'
          }
        ]
      }

    }
  },
  computed: {
    id() {
      console.log(this.id)
      return this.$route.query.id
    }
  },
  created() {
    // 获取登录用户公司信息
    this.getUser()
  },
  methods: {
    // 页面跳转
    goTo() {
      this.$router.push({ path: '/setting/changeInfor' })
    },

    // 密码修改 校验并提交
    fromPwdValidateAndSubmit() {
      const { formPwd } = this.$refs
      if (!formPwd) return
      formPwd.validate((valid) => {
        if (!valid) return
        else {
          const params = {
            loginPwd: md5(this.formPwd.loginPwd),
            newLoginPwd: md5(this.formPwd.newLoginPwd),
            confirmNewLoginPwd: md5(this.formPwd.confirmNewLoginPwd)
          }
          this.updateUserPassword(params)
        }
      })
    },
    fromPhoneValidateAndSubmit() {
      const { formPhone } = this.$refs
      if (!formPhone) return
      formPhone.validate((valid) => {
        if (!valid) return
        else {
          const params = {
            userId: this.userId,
            mobilephone: this.formPhone.mobilePhone
          }
          this.updateUser(params)
        }
      })
    },

    // 用户信息
    async getUser() {
      const { data } = await this.$http.accountSetting.getUser({ userId: this.userId })
      if (data.code !== '0') {
        return this.$message.error(data.msg || '访问不成功！')
      } else {
        // console.log('数据:', data)
        this.accountInfo = data.data
      }
    },
    // 修改密码
    async updateUserPassword(params) {
      const { data } = await this.$http.accountSetting.updateUserPassword(params)
      if (data.code !== '0') {
        return this.$message.error(data.msg || '访问不成功！')
      } else {
        // console.log('数据:', data)
        this.$message.success(data.msg || '访问成功！')
        this.dialogFormPwd = false
      }
    },
    // 修改手机号码
    async updateUser(params) {
      const { data } = await this.$http.accountSetting.updateUser(params)
      if (data.code !== '0') {
        return this.$message.error(data.msg || '访问不成功！')
      } else {
        // console.log('数据:', data)
        this.$message.success(data.msg || '访问成功！')
        this.getUser()
        this.dialogFormPhone = false
      }
    }

  }
}
</script>

<style lang="scss" scrope>
.setting-manage-page {
  
  .row-line{
    padding: 20px 0 10px 160px;
  }
  .col-line{
    text-align: left;
  }
  .iconstyle{
    color: #3E6DF5;
    font-size: 14px;
    line-height: 20px;
  }
  .account-title{
    color: #999999;
    font-size:14px;
    margin-left: 10px;
  }
  .account-content{
    color:rgba(51,51,51,1);
    font-size: 14px;
  }
  .account-passw{
    font-weight: 900;
  }
  .button{
    padding: 0;
  }
  .avatar{
    width: 72px;
    height: 72px;
  }
  .btns{
    color: #FFA00C;
  }

  .btns:hover{
    color: #fcc266;
  }

  .el-input{
    width: 300px;
  }
  
}
</style>
